import { IBasicUtil } from "@/interfaces/IBasicUtil";
import { Ref } from "vue";
import { ActiveLoader, useLoading } from 'vue-loading-overlay'

export class BasicUtil implements IBasicUtil {

  private loading: ActiveLoader | undefined
  private loadingCount: number = 0


  public async showFullLoading() {
    if (this.loadingCount == 0) {
      const $loading = useLoading()
      this.loading = $loading.show({
        color: '#007bff',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.3,
        zIndex: 999,
      });
    }
    this.loadingCount += 1
  }

  public async showLoading(component: any, isFullScreen: boolean) {
    if (this.loadingCount == 0) {
      const $loading = useLoading()
      console.log(component.$refs.root)
      this.loading = $loading.show({
        color: '#007bff',
        loader: 'spinner',
        container: isFullScreen ? null : component.$refs.root,
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.3,
        zIndex: 999,
      });
    }
    this.loadingCount += 1
  }

  public async hideLoading() {
    this.loadingCount -= 1
    if (this.loadingCount == 0) {
      this.loading?.hide()
    }
  }

}
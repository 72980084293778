
import { DefaultBasicUtil, IBasicUtil } from "@/interfaces/IBasicUtil";
import {
  DefaultHttpClient,
  ICalcResponse,
  IHttpClient,
  IQuestionResponse,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject } from "vue";
import Header from "@/components/Header.vue";
import { useRouter } from "vue-router";
import Cookies from 'js-cookie';
export default defineComponent({
  name: "Question",
  components: {
    Header,
  },
  data() {
    return {
      isAnswer: false as boolean,
      question: null as IQuestionResponse | null,
      inputText: "" as string,
      point: "-" as string,
      isReported: false as boolean,
      unScored: false as boolean,
      result: null as ICalcResponse | null,
      lengthMessage: "" as string,
      bunpoTexts: [
        "",
        "文章を正しく入力できていないかもしれません。",
        "",
        "誤字脱字、文法的な正しさ、記述量などを確認しましょう。",
        "",
        "誤字脱字、文法、記述量など正しく回答できているようです。",
      ] as string[],
      bunpoLevel: 0 as number,
      termTexts: [
        "",
        "用語の使用が不正確かつ不十分と思われます",
        "もう少し正しい用語を使用するようにしましょう",
        "もう少し用語の使用を改善しましょう",
        "ほぼ正しい用語を記述出来ているようです",
        "必要な用語を正しく記述できているようです",
      ] as string[],
      termLevel: 0 as number,
      semanticTexts: [
        "",
        "不正確な内容となっているようです",
        "もう少し正しい内容を記述しましょう",
        "記述の内容をもう少し改善しましょう",
        "ほぼ正しい記述の内容になっているようです",
        "完全に正確な内容が記述できているようです",
      ] as string[],
      semanticLevel: 0 as number,
      criticalWord: "" as string,
      unnaturalWord: "" as string,
      rating: "0" as string,
      hasRating: Cookies.get('ksr') == "ksr",
      endRating: false,
      alfaScore: ["", "E", "D", "C", "B", "A"],
      isLoading: false as boolean
    };
  },
  setup() {
    return {
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil),
      httpClient: inject<IHttpClient>(DefaultHttpClient),
    };
  },
  async created() {
    this.getData(Number(this.$route.params.id));
  },
  async mounted() {
    this.httpClient!.ready();
    document.getElementById("topNav")!.style.display = "none";
    document.scrollingElement!.scrollTop = 0;
  },

  methods: {
    async getData(id: number) {
      this.question = await this.httpClient!.getQuestion(id);
    },
    prev() {
      const id = Number(this.$route.params.id) - 1;
      if (id < 1) return;

      this.$router.push("/question/" + String(id));
      this.resetData();
      this.getData(id);
    },
    next() {
      const id = Number(this.$route.params.id) + 1;

      this.$router.push("/question/" + String(id));
      this.resetData();
      this.getData(id);
    },
    resetData() {
      this.isAnswer = false;
      this.question = null;
      this.inputText = "";
      this.point = "-";
      this.isReported = false;
      this.unScored = false;
      this.result = null;
      this.bunpoLevel = 0;
      this.termLevel = 0;
      this.semanticLevel = 0;
      this.criticalWord = "";
      this.unnaturalWord = "";
      this.rating = "0"
      this.lengthMessage = ""
      this.hasRating = Cookies.get('ksr') == "ksr"
      this.endRating = false
    },
    changeTextarea() {
      this.lengthMessage = ""
    },
    async answer() {
      this.lengthMessage = ""
      if (!window.confirm("回答を送信しますか？")) {
        return;
      }
      if (this.inputText.length < 16) {
        this.lengthMessage = "回答文字数が少なすぎます"
        return
      }
      if (this.inputText.length > this.question!.max * 1.05) {
        this.lengthMessage = "回答文字数が多すぎます"
        return
      }
      if (!this.isValid(this.inputText)) {
        this.isAnswer = true;
        this.unScored = true;
        return;
      }

      this.isLoading = true
      this.result = await this.httpClient!.calc({
        id: this.question!.question_id,
        text: this.inputText,
      });
      console.log(this.result)

      this.isAnswer = true;
      this.isLoading = false
      if (this.result == null) return;

      this.point = String(Math.round(this.result.score * 100));
      this.setBunpo(this.result.bunpo_score);
      this.setSemantic(this.result.semantic_score);
      this.setTerm(this.result.term_score);
      this.setCriticalWord(this.result.critical_words);
      this.setUnnaturalWord(this.result.unnatural_words);
    },
    isValid(text: string) {
      if (text.length < 10) return false;
      if (text.match(/^[A-Za-z0-9ａ-ｚＡ-Ｚ]*$/)) return false;
      return true;
    },
    report() {
      if (!window.confirm("不正確な採点として報告しますか？")) {
        return;
      }
      this.isReported = true;
      this.httpClient!.report(this.question!.question_id, this.inputText);
    },
    setBunpo(score: number) {
      this.bunpoLevel = 1;
      if (score > 0.72) this.bunpoLevel = 3;
      if (score > 0.94) this.bunpoLevel = 5;
    },
    setSemantic(score: number) {
      this.semanticLevel = 1;
      if (score > 0.30) this.semanticLevel = 2;
      if (score > 0.45) this.semanticLevel = 3;
      if (score > 0.7) this.semanticLevel = 4;
      if (score > 0.84) this.semanticLevel = 5;
    },
    setTerm(score: number) {
      this.termLevel = 1;
      if (score > 0.3) this.termLevel = 2;
      if (score > 0.5) this.termLevel = 3;
      if (score > 0.72) this.termLevel = 4;
      if (score > 0.85) this.termLevel = 5;
    },
    setCriticalWord(texts: string[]) {
      if (texts.length == 0) return;
      this.criticalWord = texts.join(", ");
    },
    setUnnaturalWord(texts: string[]) {
      if (texts.length == 0) return;
      this.unnaturalWord = texts.join(", ");
    },
    sendRating() {
      if (!window.confirm("評価を確定しますか？")) {
        return;
      }
      this.httpClient?.rating(Number(this.rating))
      Cookies.set('ksr', 'ksr')
      this.endRating = true
    }
  },
});

import { InjectionKey, Ref } from "vue";

export const DefaultBasicUtil: InjectionKey<IBasicUtil> = Symbol("util");
export interface IBasicUtil {
  

  showFullLoading: () => void;
  /**
   * loading表示
   * component: VueComponent(this)
   * isFullScreen: 全画面表示か否か
   */
  showLoading: (component: any, isFullScreen: boolean) => void;

  /**
   * loading非表示
   */
  hideLoading: () => void;

}

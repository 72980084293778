
import { defineComponent, inject, onMounted, provide, ref } from "vue";
import Header from '@/components/Header.vue'
export default defineComponent({
  components: {
    Header
  },
  data() {
    return {

    }
  },
  setup() {
    return {
    };
  },
  created() {},
  mounted() {},
});

import { IBasicUtil } from "@/interfaces/IBasicUtil";
import { ICalcRequest, ICalcResponse, IHttpClient, IQuestionResponse } from "@/interfaces/IHttpClient";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export class HttpClient implements IHttpClient {
  private axiosInstance: AxiosInstance | undefined
  private axiosNoAuthNoLoadingInstance: AxiosInstance | undefined
  private basicUtil: IBasicUtil | undefined
  constructor(basicUtil: IBasicUtil) {
    this.basicUtil = basicUtil
    this.axiosInstance = this.getBasicInstance()
    this.axiosNoAuthNoLoadingInstance = this.getBasicInstance(false)
  }

  private getBasicInstance(isSetLoading: boolean = true): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: "https://jali1-46ftmh4hwq-an.a.run.app/",
      headers: {
        token: "X/CmcXUJb-(g!WsT8Ud3)fz!UD$8L_dQ"
      }
    });
    axiosInstance.interceptors.request.use((request) => {
      if (isSetLoading) this.basicUtil?.showFullLoading()
      return request;
    });
    axiosInstance.interceptors.response.use(
      (response) => {
        if (isSetLoading) this.basicUtil?.hideLoading()
        return response;
      },
      (error) => {
        if (isSetLoading) this.basicUtil?.hideLoading()
        throw error;
      }
    );
    return axiosInstance
  }

  public async ready() {
    this.axiosNoAuthNoLoadingInstance!.get("ready")
  }

  public async getQuestions() {
    return this.axiosNoAuthNoLoadingInstance!.get<IQuestionResponse[]>("questions").then((res: AxiosResponse<IQuestionResponse[]>) => {
      return res.data
    }).catch((e) => {
      return []
    })
  }

  public async getQuestion(id: number) {
    return this.axiosInstance!.get<IQuestionResponse>("question?id=" + String(id)).then((res: AxiosResponse<IQuestionResponse>) => {
      return res.data
    }).catch((e) => {
      return null
    })
  }
  
  public async calc(data: ICalcRequest) {
    return this.axiosNoAuthNoLoadingInstance!.post<ICalcResponse>("calc", data).then((res: AxiosResponse<ICalcResponse>) => {
      return res.data
    }).catch((e) => {
      return null
    })
  }

  public async report(id: number, text: string) {
    this.axiosInstance!.post<void>("report", {id: id, text: text})
  }

  public async rating(rate: number) {
    this.axiosInstance!.post<void>("rating", {rate: rate})
  }

  public async contact(email: string, text: string) {
    return axios.post("https://us-central1-i407-333113.cloudfunctions.net/notify", {
          email: email,
          title: "監査論",
          content: text
        },{
          headers: {
          token: await (window as any).get_token()
          }
        })
  }
}
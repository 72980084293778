import { InjectionKey } from "vue";

export const DefaultHttpClient: InjectionKey<IHttpClient> = Symbol("api");
export interface IHttpClient {
  ready: () => void
  getQuestions: () => Promise<IQuestionResponse[]>;
  getQuestion: (id: number) => Promise<IQuestionResponse|null>;
  calc: (data: ICalcRequest) => Promise<ICalcResponse|null>
  report: (id: number, text: string) => void
  rating: (rate: number) => void
  contact: (email: string, text: string) => any
}

export interface IQuestionResponse {
  question_id: number,
  text: string,
  max: number,
  min: number
}

export interface ICalcRequest {
  id: number,
  text: string
}

export interface ICalcResponse {
  answer: IAnswer,
  bunpo_score: number,
  bunsetu: any,
  critical_score: number,
  critical_words: string[],
  grammers: Grammer[],
  length_score: number,
  natural_score: number,
  score: number,
  semantic_score: number,
  term_score: number,
  unnatural_words_score: number,
  valid_score: number,
  words_score: number,
  unnatural_words: string[]
}

export interface IAnswer {
  criticals: string[][], 
  words: string[][], 
  text: string,
}

export interface Grammer {
  score: number, 
  word: string,
}

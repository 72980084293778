
import { DefaultBasicUtil, IBasicUtil } from "@/interfaces/IBasicUtil";
import {
  DefaultHttpClient,
  IHttpClient,
  IQuestionResponse,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject, onMounted } from "vue";
export default defineComponent({
  name: "Home",
  components: {},
  data() {
    return {
      questions: [] as IQuestionResponse[],
    };
  },
  setup() {
    return {
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil),
      httpClient: inject<IHttpClient>(DefaultHttpClient),
    };
  },
  async created() {
    (window as any).topFunc();
  },

  async mounted() {
    document.getElementById("topNav")!.style.display = "block";
    this.basicUtil!.showLoading(this, false);
    const questions = await this.httpClient!.getQuestions();
    this.basicUtil!.hideLoading();

    questions.sort(function (a, b) {
      if (a.question_id < b.question_id) return -1;
      if (a.question_id > b.question_id) return 1;
      return 0;
    });

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].text.length > 30)
        questions[i].text = questions[i].text.substring(0, 30) + "...";
    }
    this.questions = questions;
  },
});

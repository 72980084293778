
import { defineComponent, inject, onMounted, PropType } from 'vue';
export default defineComponent({
  name: 'Header',
  components: {
    
  },
  props: {
    pid: Object as PropType<string>,
    pclass: Object as PropType<string>,
  },
  data() {
    return {
      shareUrl: encodeURIComponent("https://kansa.gold-study.com") as string,
      shareText: encodeURIComponent("公認会計士試験監査論論文式対策アプリ\nAIによる採点・分析システム\n#AI監査論\n\n") as string,
    }
  },
});


import { DefaultBasicUtil, IBasicUtil } from "@/interfaces/IBasicUtil";
import {
  DefaultHttpClient,
  IHttpClient,
} from "@/interfaces/IHttpClient";
import { defineComponent, inject } from "vue";
import Header from "@/components/Header.vue";
export default defineComponent({
  name: "Contact",
  components: {
    Header,
  },
  data() {
    return {
      mail: "" as string,
      body: "" as string,
      isSend: false as boolean
    }
  },
  setup() {
    return {
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil),
      httpClient: inject<IHttpClient>(DefaultHttpClient),
    };
  },
  async created() {
  },
  async mounted() {
    document.getElementById("topNav")!.style.display = "none";
    document.scrollingElement!.scrollTop = 0;
  },

  methods: {
    async send(){
      const form: HTMLFormElement = document.getElementById("form")! as HTMLFormElement
        if (!form.reportValidity())
        {
          return
        }
        this.basicUtil?.showFullLoading()
        const vm = this
        this.httpClient!.contact(this.mail, this.body).then(() => {
          vm.mail = ""
          vm.body = ""
          vm.isSend = true
          this.basicUtil?.hideLoading()
        }).catch((e: any) => {
          console.log(e)
          this.basicUtil?.hideLoading()
        })
        
      }
  },
});
